import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
export const Map = (props) => {
    const [center, setCenter]=useState()

    const mapContainerStyle = {
        width: "100%",
        height: "calc(100vh - 48px)"
    };

    const zoom=15;

    const setCenterPosition=()=>{
        var maxLatitude=Math.min(...props.coordinates?.map(o => o.latitude));
        var maxLongitude=Math.min(...props.coordinates?.map(o => o.longitude));;
        const centerArray = {
            lat: maxLatitude,
            lng: maxLongitude, 
        };  
        setCenter(centerArray);
    }
    return (
        <LoadScript googleMapsApiKey="AIzaSyDhv9l_yb3DDJqtCKGqsES-qW0ak83x4Ko">
            <GoogleMap key="routeMap"
                mapContainerStyle={mapContainerStyle}
                center={{lat:props.coordinates[0].latitude, lng:props.coordinates[0].longitude}}
                //center={{lat:center.lat, lng:center.lng}}
                zoom={props.withMarkers==false?5:(props.coordinates?.length>1?(props.coordinates?.length>5?5: 12):15)}
                options={{
                    scrollwheel: true, draggable: true, mapTypeControl: true,
                    disableDefaultUI: false,
                }}
            >
                { props.withMarkers &&  props.coordinates
                    ?props.coordinates.map((coordinate)=>(
                        <Marker key={coordinate.id+ coordinate.latitude-coordinate.longitude}
                            position={{lat:parseFloat(coordinate.latitude), lng:parseFloat(coordinate.longitude)}} 
                            
                            onLoad={marker => {
                                const customIcon = (opts) => Object.assign({
                                  path: 'M92.58 0l-0.03 0 -0.02 0 -0.02 0c-0.42,0 -0.84,0.01 -1.25,0.02l-0.03 0 -0.02 0 -0.02 0 -0.02 0 -0.07 0c-1.54,0.03 -3.06,0.09 -4.57,0.2 -48.31,3.12 -86.53,43.26 -86.53,92.36 0,25.42 11.21,47.56 26.85,65.19 12.49,14.06 27.95,32 36.96,48.28l24.16 43.65c0.93,1.67 2.69,2.71 4.61,2.71 1.91,0 3.67,-1.04 4.6,-2.71l24.16 -43.65c9.01,-16.28 24.48,-34.22 36.96,-48.28 15.64,-17.62 26.85,-39.76 26.85,-65.19 0,-49.1 -38.22,-89.24 -86.53,-92.36 -1.46,-0.1 -2.93,-0.17 -4.4,-0.2l-0.17 0 -0.07 0 -0.02 0 -0.02 0 -0.02 0c-0.43,-0.01 -0.85,-0.02 -1.28,-0.02l-0.02 0 -0.02 0 -0.02 0zM76.84 72.91c0,-8.68 7.06,-15.74 15.74,-15.74 8.67,0 15.73,7.06 15.73,15.74 0,8.41 -6.63,15.3 -14.94,15.71 -0.26,0 -0.53,-0.01 -0.79,-0.01 -0.27,0 -0.53,0.01 -0.8,0.01 -8.31,-0.41 -14.94,-7.3 -14.94,-15.71zm57.49 49.62c-0.37,-1.77 -2.11,-2.91 -3.88,-2.54 -1.77,0.37 -2.91,2.1 -2.54,3.87 0.21,1.03 -0.04,2.07 -0.7,2.88 -0.38,0.46 -1.22,1.24 -2.62,1.24l-64.03 0c-1.4,0 -2.24,-0.78 -2.62,-1.24 -0.66,-0.81 -0.91,-1.85 -0.7,-2.88 3.41,-16.34 17.82,-28.28 34.47,-28.68 0.28,0.01 0.57,0.02 0.87,0.02 0.29,0 0.58,-0.01 0.87,-0.02 11.72,0.28 22.56,6.22 29.12,16.01 1.01,1.5 3.05,1.91 4.55,0.9 1.51,-1.01 1.91,-3.05 0.9,-4.55 -5.38,-8.02 -13.19,-13.86 -22.1,-16.79 5.43,-4.07 8.95,-10.55 8.95,-17.84 0,-12.3 -10,-22.3 -22.29,-22.3 -12.3,0 -22.3,10 -22.3,22.3 0,7.29 3.52,13.78 8.96,17.85 -4.98,1.64 -9.65,4.19 -13.74,7.55 -7.5,6.17 -12.71,14.77 -14.68,24.22 -0.61,2.96 0.13,6 2.03,8.34 1.9,2.33 4.71,3.67 7.71,3.67l64.03 0c3,0 5.81,-1.34 7.71,-3.67 1.46,-1.79 2.23,-3.99 2.24,-6.25l0 -0.03c0,-0.68 -0.07,-1.38 -0.21,-2.06z',
                                  fillColor: '#000000',
                                  fillOpacity: 1,
                                  strokeColor: '#000',
                                  strokeWeight: 1,
                                  scale: .18,
                                }, opts);
                    
                                marker.setIcon(customIcon({
                                  fillColor:coordinate.color,
                                  strokeColor: 'white'
                                }));
                              }}
                        />     
                    )) 
                    :<></>
                }
            </GoogleMap>
        </LoadScript>
    );
};