import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Documents/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Configuration/Documents/DocumentStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';

export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [document, setDocument] = useState([]);
    const [buttonsDocument, setButtonsDocument] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            var branches=await GetBranchsAsync();
            var branchFilter=branches.data?.find((b)=>b.key.toString()==result.data.branchId.toString())
            var branchName = branchFilter !== undefined ? (branchFilter.brachKey + " - " + branchFilter.text) : "";
            console.log(result.data);


            setDocument({
                id: result.data.id,
                serie:result.data.serie,
                branchId:result.data.branchId,
                branchName:branchName,
                documentTypeId:result.data.documentTypeId,
                documentTypeDescription:result.data.documentTypeDescription,
                isFiscal:result.data.isFiscal,
                visibleInMatrix:result.data.visibleInMatrix,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !document.active);
        if (result.isSuccessful) {
            setDocument((prevDocument) => {
                return {
                    ...prevDocument,
                    active: !document.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = document.active ? "Inactivar" : "Activar";
                let statusiconbutton = document.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = document.active ? 494 : 495
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la ubicación?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !document.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (document) {
            setButtonsDocument(prevDocument => ({
                ...prevDocument,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...document };
            updateItemStatus({ ...data, acronym: localStorage.getItem("userName"), description:data.documentTypeDescription });
        }
    }, [document?.active]);

    useEffect(() => {
        if (document) {
            setButtonsDocument(prevDocument => ({
                ...prevDocument,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);   

    return (
        <>
            {document.length !== 0 &&
                (<BarButtons rightButtons={buttonsDocument.rightButtons}
                leftButtons={buttonsDocument.leftButtons} />)}
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Datos generales'>
                        <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Tipo de documento' value={document.documentTypeDescription} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Serie' value={document.serie} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Sucursal' value={document.branchName} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Documento es visible en matriz' 
                                    value={document.visibleInMatrix?"Si":"No"} />
                                </div>
                            </div> 
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Es fiscal' 
                                    value={document.isFiscal?"Si":"No"} />
                                </div>
                            </div> 
                        </OmniCard>
                    </div>
                </div>
                    </div> 
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={document} />
                </PivotItem>
            </Pivot>
        </>
    )
}