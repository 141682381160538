import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/TransferRoutes/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetToAutocomplete, SetAxiosPrivate as SetAxiosPrivateWarehouses} from '../../../Functions/Configuration/Warehouse/WarehouseStore'
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/TransferRoutes/TransferRouteStore';
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const iconCatalog = {
    src: require('../../../Assets/Images/Icons/TransferRoute.svg').default
}

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [transferRoute, setTransferRoute] = useState([]);
    const [warehouses,setWarehouses]=useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [warehouseLoad, setWarehouseLoad] = useState();
    const [serchClient, setSerchClientr] = useState('');
    const [warehouseName, setWarehouseName]=useState('');
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateWarehouses(axiosPrivate);

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };


    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            if (transferRoute === undefined || transferRoute.title === undefined) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage("El nombre es obligatorio")]);
            }
            else {
                var data = getValues();
                data.issuingWarehouseId = parseInt(transferRoute.issuingWarehouseId);
                data.receiveWarehouseId = parseInt(transferRoute.receiveWarehouseId);
                let result = await UpdateAsync(id, data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, id);
                    data.id = id;
                    updateItem({ ...data, acronym: localStorage.getItem("userName") });
                    setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    const GetWarehouses = async()=>{
        let result = await GetToAutocomplete();
        if (result.isSuccessful) {
            var warehouses= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setWarehouses(warehouses);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const onChangeItemWarehouseIssuing = async (id, transferRoute) => {
        setTransferRoute(prevEedit => ({
            ...prevEedit,
            issuingWarehouseId: id,
            transferRoute: transferRoute
        }));
    }

    const OnChangeWarehouseTextIssuing = async (text) => {
        if (warehouseLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }

    const onChangeItemWarehouseReceive = async (id, transferRoute) => {
        setTransferRoute(prevEedit => ({
            ...prevEedit,
            receiveWarehouseId: id,
            transferRoute: transferRoute
        }));
    }

    const OnChangeWarehouseTextReceive = async (text) => {
        if (warehouseLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }


    function ChangeText(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = warehouseLoad.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.text.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                setWarehouses(filteredData);
            }
        }
    }

    useEffect(() => {
        GetWarehouses();
        const fillData = async () => {
            try {
                const data = await getById();                
                setTransferRoute({
                    title:data?.title,
                    issuingWarehouseName: data?.issuingWarehouseName,
                    issuingWarehouseId: data?.issuingWarehouseId,
                    receiveWarehouseName: data?.receiveWarehouseName,
                    receiveWarehouseId: data?.receiveWarehouseId
                });
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])
    useEffect(() => {
        if (warehouses && transferRoute) {
            setWarehouses(prevWarehouses => [
                ...prevWarehouses,
                {
                    key: transferRoute.warehouseId,
                    value: transferRoute.warehouseId,
                    text: transferRoute.warehouseName
                }
            ]);
        }
        
    }, [transferRoute])
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <div className='row'>
                                    {
                                        transferRoute.length !== 0 &&
                                        (
                                            <>
                                             <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                                <OmniTextBox title='Nombre' maxLength={64} register={{ ...register('title') }} value={transferRoute.title} setValue={setValue} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                    <OmniCombobox
                                                        title='Almacén emisor'
                                                        items={warehouses}
                                                        selected={transferRoute?.issuingWarehouseId}
                                                        value={transferRoute?.issuingWarehouseName}
                                                                    onChangeItem={onChangeItemWarehouseIssuing} requiredOmni defaultValue  
                                                        onChangeItemText={OnChangeWarehouseTextIssuing} 
                                                        image={iconCatalog}/>
                                                    
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                    <OmniCombobox
                                                        title='Almacén receptor'
                                                        items={warehouses}
                                                        selected={transferRoute?.receiveWarehouseId}
                                                        value={transferRoute?.receiveWarehouseName}
                                                                    onChangeItem={onChangeItemWarehouseReceive} requiredOmni defaultValue   
                                                        onChangeItemText={OnChangeWarehouseTextReceive} 
                                                        image={iconCatalog}/>
                                                    
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </OmniCard>
                        </div>
                    </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

