const urlSupplier = {
    base: '/supplier',
    add: '/add',
    get: '/getbyid',
    updateStatus: '/setstatus',
    update: '/update',
    getLastAccountingAccount: '/getLastAccountingAccount',
    getByStatus: '/getbystatus',
    getByActive: '/getByActive'
}
const urlCalculationMethod = '/calculationmethod/getByStatus';
const urlCreditChargeType = '/creditchargetype/getByStatus';

const SupplierContactEndpoint = {
    controller: "SupplierContact",
    actions: {
        updateStatus: "setstatus",
        add: "add",
        update:"update"
    }
}

var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

/* CALCULATION METHOD */
export const GetCalculationMethodAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(urlCalculationMethod,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

/* CREDIT CHARGE TYPE */
export const GetCreditChargeTypeAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(urlCreditChargeType,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}


/* SUPPLIER */
/* Get */
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(urlSupplier.base + urlSupplier.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

/* Add */
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let identificator = data.id ? data.id : 0;
        const response = await axiosPrivate.post(urlSupplier.base + urlSupplier.add,
            JSON.stringify({
                id: identificator,
                ...data,
                UpdateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `El proveedor se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

/* Update */
export const UpdateAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(urlSupplier.base + urlSupplier.update,
            JSON.stringify({
                ...data,
                UpdateUserId: localStorage.getItem("userId"), 
                updateUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El proveedor se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

/* Update Status*/
export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(urlSupplier.base + urlSupplier.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El proveedor ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateStatusContactsAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(SupplierContactEndpoint.controller, SupplierContactEndpoint.actions.updateStatus).url,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El contacto ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const AddContactsAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(SupplierContactEndpoint.controller, SupplierContactEndpoint.actions.add).url,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El contacto se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateContactAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(SupplierContactEndpoint.controller, SupplierContactEndpoint.actions.update).url,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El contacto se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const GetLastAccountingAccountAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(urlSupplier.base + urlSupplier.getLastAccountingAccount,
            null);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const GetByStatus = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(urlSupplier.base + urlSupplier.getByStatus,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetByActiveAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(urlSupplier.base + urlSupplier.getByActive);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    }
    else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}
