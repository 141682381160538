import { Label, Tooltip } from "@fluentui/react-components";
import { ReactComponent as AttendanceLog } from '../../../../Images/Icons/AttendanceLog.svg';
import { ReactComponent as ContractCuvitek } from '../../../../Images/Icons/Contract.svg';
import { ReactComponent as ExpandCuvitek } from '../../../../Images/Icons/Expand.svg';
import { ReactComponent as EmployeeWithoutFill} from '../../../../Images/Icons/EmployeeWithoutFill.svg';
import { ReactComponent as Group } from '../../../../Images/Icons/Group.svg';
export const leftButtons = () => [{
    key: 'LblTitle',
    text: <div className='row'>
        <div className='col-lg-12 col-md-12'>
            <AttendanceLog className="color-info-fill" />
            <span className="home-header-text-button">
                Asistencias
            </span>
        </div>
    </div>,
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickTrigger, handleClickNewQuery, contract, handleClickContract, daysDocument) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar Reporte' : '',
    iconName: 'gridCuvitek',
    iconColor: daysDocument === -1 ? 'var(--secondary)' : '#fff',
    iconHover: daysDocument === -1 ? 'var(--secondary)' : '#fff',
    handler: handleClickTrigger,
    className: daysDocument === -1 ? '' : 'BtnTrigger',
    disabled: daysDocument === -1 ? true : false
},
{
    key: 'BtnExcel',
    text: contract ? 'Exportar a Excel' : '',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
},
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickNewQuery
},
{
    key: 'BtnContract',
    text:
        <div className='mt-1 contract-right'>
            {contract ? <ContractCuvitek /> : <ExpandCuvitek />}
        </div>,
    handler: handleClickContract
}
]
export const rightButtonsDisabled = (handleClickTrigger, contract, handleClickContract) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar Reporte' : '',
    iconName: 'gridCuvitek',
    iconColor: '#fff',
    iconHover: '#fff',
    handler: handleClickTrigger,
    className: 'BtnTrigger'
},
{
    key: 'BtnExcel',
    text: contract ? 'Exportar a Excel' : '',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
},
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
},
{
    key: 'BtnContract',
    text:
        <div className='mt-1 contract-right'>
            {contract ? <ContractCuvitek /> : <ExpandCuvitek />}
        </div>,
    handler: handleClickContract
}
]
export const rightButtonsExec = (handleClickExcel, handleClickNewQuery, contract, handleClickContract) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar reporte' : '',
    iconName: 'gridCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
}, {
    key: 'BtnExcel',
    text: contract ? 'Exportar a Excel' : '',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    handler: handleClickExcel
},
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickNewQuery
},
{
    key: 'BtnContract',
    text:
        <div className='mt-1 contract-right'>
            {contract ? <ContractCuvitek /> : <ExpandCuvitek />}
        </div>,
    handler: handleClickContract
}
]
export const rightButtonsExecUngrouped = (handleClickDownload,handleClickExcel, handleClickNewQuery, contract, handleClickContract) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar reporte' : '',
    iconName: 'gridCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
}, {
    key: 'BtnExcel',
    text: contract ? 'Exportar a Excel' : '',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    handler: handleClickExcel
    },
    //{
    //    key: 'BtnTxt',
    //    text: contract ? 'Descargar TXT de TimeWork' : '',
    //    iconName: 'downloadCloudCuvitek',
    //    iconColor: 'var(--info)',
    //    iconHover: 'var(--info)',
    //    handler: handleClickDownload
    //},
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickNewQuery
},
{
    key: 'BtnContract',
    text:
        <div className='mt-1 contract-right'>
            {contract ? <ContractCuvitek /> : <ExpandCuvitek />}
        </div>,
    handler: handleClickContract
}
]
export const rightButtonsSecond = (handleClickCalendar, handleClickEmployee, textDate, listEmployee, textGroup, handleClickGroup) => [
    {
        key: 'BtnEmployee',
        text: <Tooltip content={listEmployee?.length === 0 ? "Todos" : <>
            {listEmployee.map((item, index) => (
                <div key={index} className="col-12">
                    {item}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-info'>
                    <EmployeeWithoutFill className="icon-info-label" />
                    <Label className='cursor-pointer'>Empleados</Label>
                </div>
            </div>
        </Tooltip>,
        handler: handleClickEmployee
    },
    {
        key: 'BtnCalendar',
        text: textDate,
        iconName: 'dateRangeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--info)',
        handler: handleClickCalendar
    },
    {
        key: 'BtnGroup',
        text: <Tooltip content={textGroup} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-info'>
                    <Group className="icon-info-label" />
                    <Label className='cursor-pointer'>Agrupado</Label>
                </div>
            </div>
        </Tooltip>,
        handler: handleClickGroup
    }
]
export const rightButtonsSecondDisabled = (textDate, listEmployee, textGroup) => [
    {
        key: 'BtnEmployee',
        text: <Tooltip content={listEmployee?.length === 0 ? "Todos" : <>
            {listEmployee.map((item, index) => (
                <div key={index} className="col-12">
                    {item}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-disabled'>
                    <EmployeeWithoutFill />
                    <Label className='cursor-not-allowed'>Empleados</Label>
                </div>
            </div>
        </Tooltip>,
        disabled: true
    },
    {
        key: 'BtnCalendar',
        text: textDate,
        iconName: 'dateRangeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
    {
        key: 'BtnGroup',
        text: <Tooltip content={textGroup} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-disabled'>
                    <Group/>
                    <Label className='cursor-not-allowed'>Agrupado</Label>
                </div>
            </div>
        </Tooltip>,
        disabled: true
    }
]