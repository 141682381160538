import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/IepsTax/Edit';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/IepsTax/IepsStore';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';

const pivotStyles = {
    root: {
        width: '95%'
    }
};

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const id = useIdSelectorContext();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const { useAccounting } = useAccountingConfigurationContext();
    const [ieps, setIeps] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    SetAxiosPrivate(axiosPrivate);
    const [showErrors, setShowErrors] = useState({
        clave: undefined,
        name: undefined,
    });
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };
    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
                var data = ieps;
                let errorMessagesIeps = [];
                if (data) {
                    if (!data.transferAccountingAccount) {
                        errorMessagesIeps.push("El campo impuesto trasladado es obligatorio");
                    }
                    if (!data.creditableAccountingAccount) {
                        errorMessagesIeps.push("El campo impuesto acreditable es obligatorio");
                    }
                }
                if (errorMessagesIeps.length > 0) {
                    setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessagesIeps.map(x => x).join('. '))]);
                }
                else {
                    if (useAccounting) {
                        data.transferAccountingAccount = data.transferAccountingAccount.endsWith(".") ? data.transferAccountingAccount.slice(0, -1) : data.transferAccountingAccount;
                        data.creditableAccountingAccount = data.creditableAccountingAccount.endsWith(".") ? data.creditableAccountingAccount.slice(0, -1) : data.creditableAccountingAccount;
                        data.accountingAccountPendingTransfer = data.accountingAccountPendingTransfer ? data.accountingAccountPendingTransfer.endsWith(".") ? data.accountingAccountPendingTransfer.slice(0, -1) : data.accountingAccountPendingTransfer : null;
                        data.accountingAccountPendingCredit = data.accountingAccountPendingCredit ? data.accountingAccountPendingCredit.endsWith(".") ? data.accountingAccountPendingCredit.slice(0, -1) : data.accountingAccountPendingCredit : null;
                    }
                    let result = await UpdateAsync(id, data);
                    if (result.isSuccessful) {
                        changeComponent(<Detail />, id);
                        data.id = id;
                        updateItem({ ...data, acronym: localStorage.getItem("userName") });
                        setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
                    }
                    else {
                        setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
                    }
                }
            }
    }
    useEffect(() => {
        const fillData = async () => {
            try {
                const dataIeps = await getById();
                setIeps(dataIeps);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    const onBlurTransferAccountingAccount = (event) => {
        ValidText(event, "transferAccountingAccount", "impuesto trasladado");
    };
    const onBlurCreditableAccountingAccount = (event) => {
        ValidText(event, "creditableAccountingAccount", "impuesto acreditable");
    }
    function ValidText(event, property, fild) {
        let text = event.target.value.trim();
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }
    useEffect(() => {
        if (ieps) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons}/>
                <Pivot className="pivot-center-client"
                    selectedKey={selectedKey}
                    onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                    overflowBehavior="menu" styles={pivotStyles}
                >
                    <PivotItem headerText="Información" itemKey="information">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Valor' value={ieps.value} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Descripción' value={ieps.description} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                    </PivotItem>
                    {
                        useAccounting &&
                        <PivotItem headerText='Contabilidad' itemKey="contabilidad">
                            <div className='container-fluid container-scroll'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Cuentas contables'>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='row'>
                                                    <OmniTextBox
                                                        focus
                                                        defaultValue
                                                        title='Impuesto trasladado'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="transferAccountingAccount"
                                                        edit={setIeps}
                                                        value={ieps?.transferAccountingAccount}
                                                        blur={onBlurTransferAccountingAccount}
                                                        validationMessage={showErrors.transferAccountingAccount}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox
                                                        title='Impuesto acreditable'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="creditableAccountingAccount"
                                                        edit={setIeps}
                                                        value={ieps?.creditableAccountingAccount}
                                                        blur={onBlurCreditableAccountingAccount}
                                                        validationMessage={showErrors.creditableAccountingAccount}
                                                    />
                                                    </div>
                                                    <div className='row'>
                                                        <OmniTextBox
                                                            title='Impuesto pendiente de trasladar'
                                                            maxLength={16}
                                                            regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                            property="accountingAccountPendingTransfer"
                                                            edit={setIeps}
                                                            value={ieps?.accountingAccountPendingTransfer}
                                                            validationMessage={showErrors.accountingAccountPendingTransfer}
                                                        />
                                                    </div>
                                                    <div className='row'>
                                                        <OmniTextBox
                                                            title='Impuesto pendiente de acreditar'
                                                            maxLength={16}
                                                            regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                            property="accountingAccountPendingCredit"
                                                            edit={setIeps}
                                                            value={ieps?.accountingAccountPendingCredit}
                                                            validationMessage={showErrors.accountingAccountPendingCredit}
                                                        />
                                                    </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                    }
                    <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>   
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    );
}