import {
    rightButtonsSecond,
    leftButtonsSecond,
    rightButtonsExecSecond,
    leftButtonsExec,
    leftButtons,
    rightButtonsSecondDisabled
} from '../../../Assets/Constants/BarButtons/Accounting/MasterPolicy/MasterPolicy';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarButtons from '../../Shared/BarButtons/BarButtons';
import '../../../Assets/Styles/Accounting/Accounting.css';
import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import { OmniDatePicker } from '../../Shared/Controls/DatePicker/OmniDatePicker';
import { useState, useEffect, useRef } from 'react';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout, Tooltip, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import {
    SetAxiosPrivate, GetWarehouseByActive,
    GetByFilterAsync, GetAccountingPoliciesByFilterAsync,
    ToShortDateNow, DateDefault, DateDefaultDisabled, GetDate
} from '../../../Functions/Accounting/MasterPolicy/MasterPolicyStore';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { MasterPolicyIcons } from '../../../Assets/Constants/Accounting/MasterPolicyIcons';
import * as XLSX from 'xlsx-color';
import { ReactComponent as Bill } from '../../../Assets/Images/Icons/Bill.svg';
import { ReactComponent as SalesCost } from '../../../Assets/Images/Icons/SalesCost.svg';
import { ReactComponent as SupplierCreditNotes } from '../../../Assets/Images/Icons/SupplierCreditNotes.svg';
import { ReactComponent as PaymentsSuppliers } from '../../../Assets/Images/Icons/PaymentsSuppliers.svg';
import { ReactComponent as Shopping } from '../../../Assets/Images/Icons/Shopping.svg';
import { ReactComponent as CustomerCreditNotes } from '../../../Assets/Images/Icons/CustomerCreditNotes.svg';
import { ReactComponent as Payment } from '../../../Assets/Images/Icons/Payment.svg';
import { PaginateTable } from '../../Shared/Paginate/PaginateTable';
import { OverlayDrawerDate } from '../../Shared/OverlayDrawer/OverlayDrawerDate';
import {
    SetAxiosPrivate as SetAxiosPrivateRole, GetDaysDocumentByRolIdAsync
} from '../../../Functions/Configuration/Roles/RoleStore';
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
export default function MasterPolicy() {
    const columnsHeader = [
        { columnKey: "1", label: "Documento", width: '12%' },
        { columnKey: "2", label: "Almacén", width: '12%' },
        { columnKey: "3", label: "RFC", width: '12%' },
        { columnKey: "4", label: "Fecha", width: '12%' },
        { columnKey: "5", label: "Subtotal", width: '12%' },
        { columnKey: "6", label: "IVA", width: '12%' },
        { columnKey: "7", label: "IEPS", width: '12%' },
        { columnKey: "8", label: "TOTAL", width: '16%' },
    ];
    const axiosPrivate = useAxiosPrivate();
    const tableRef = useRef(null);
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateRole(axiosPrivate);
    const [messages, setMessages] = useState([]);
    const [showAttachWarehousePanel, setShowAttachWarehousePanel] = useState(false);
    const [showAttachDocumentPanel, setShowAttachDocumentPanel] = useState(false);
    const [showAttachDatePanel, setShowAttachDatePanel] = useState(false);
    const [warehouseList, setWarehouseList] = useState([]);
    const [documentsList, setDocumentsList] = useState([]);
    const [policylist, setPolicylist] = useState();
    const [totalPaginate, setTotalPaginate] = useState([]);
    const [foundValue, setFoundValue] = useState();
    const [daysDocument, setDaysDocument] = useState();
    const [param, setParam] = useState({
        startDate: GetDate().startDate,
        endDate: GetDate().endDate,
        dayDiff: GetDate().dayDiff,
        warehouseType: "1",
        documentType: "1"
    });
    const itemsRadio = [
        {
            key: "1",
            value: "1",
            text: "Todos",
        },
        {
            key: "2",
            value: "2",
            text: "Los seleccionados",
        }
    ];
    const handleClickNewQuery = () => {
        setParam({
            startDate: GetDate().startDate,
            endDate: GetDate().endDate,
            dayDiff: GetDate().dayDiff,
            warehouseType: "1",
            documentType: "1",
            warehouses: undefined,
            documents: undefined
        });
        setPolicylist(undefined);
        setDocumentsList([]);
    }
    const handleClickDownload = async () => {
        try {
            const data = {
                startDate: param.startDate,
                endDate: param.endDate
            }
            if (param.warehouseType !== "1") {
                data.warehouses = warehouseList.filter(x => x.selected).map(x => x.id);
            }
            else {
                data.warehouses = [];
            }
            if (param.documentType !== "1") {
                data.documents = documentsList;
            } else {
                data.documents = [];
            }
            let result = await GetAccountingPoliciesByFilterAsync(data);
            if (result && result.data) {
                const urlImagen = URL.createObjectURL(result.data);
                const link = document.createElement("a");
                link.download = `Pólizas ${ToShortDateNow()}.txt`;
                link.href = urlImagen;
                link.click();
            }
            else {
                setMessages([new DangerMessage("No se encontraron resultados")]);
            }

        } catch (error) {

        }
    }
    const handleClickExcel = () => {
        const modifiedData = policylist.map(item => ({
            documentTypeDescription: item.documentTypeDescription,
            warehouseKey: item.warehouseKey,
            rfc: item.rfc,
            date: item.date,
            amount: item.amount.replace(/\$|,/g, ''),
            ivaAmount: item.ivaAmount.replace(/\$|,/g, ''),
            iepsAmount: item.iepsAmount.replace(/\$|,/g, ''),
            netAmount: item.netAmount.replace(/\$|,/g, '')
        }));
        let Heading = [['Documento', 'Almacén', 'RFC', 'Fecha', 'Subtotal', 'IVA', 'IEPS', 'TOTAL']];
        const wb = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.sheet_add_json(ws, modifiedData , { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        XLSX.writeFile(wb, `Pólizas ${ToShortDateNow()}.xlsx`);
    }
    const handleClickTrigger = async () => {
        setButtonsSecond(prevEdit => ({
            ...prevEdit,
            rightButtons: rightButtonsSecondDisabled(handleClickTrigger)
        }));
        if (param.startDate.setHours(0, 0, 0, 0) > param.endDate.setHours(0, 0, 0, 0)) {
            setMessages([new DangerMessage("La fecha inicial no puede ser mayor a la fecha final")]);
        }
        else {
            const data = {
                startDate: param.startDate,
                endDate: param.endDate
            }
            if (param.warehouseType !== "1") {
                data.warehouses = warehouseList.filter(x => x.selected).map(x => x.id);
            }
            else {
                data.warehouses = [];
            }
            if (param.documentType !== "1") {
                data.documents = documentsList;
            } else {
                data.documents = [];
            }
            let result = await GetByFilterAsync(data);
            if (result.isSuccessful) {
                if (result.data && result.data.length > 0) {
                    const paginatedData = result.data.slice(0, 50);
                    setTotalPaginate(result.data);
                    setPolicylist(paginatedData);
                    setButtons({
                        leftButtons: leftButtons(
                            DateDefaultDisabled(param),
                            warehouseList.filter(x => x.selected).map(x => x.name),
                            documentsList
                        )
                    });
                }
                else {
                    setMessages([new DangerMessage("No se encontraron resultados")]);
                    setButtonsSecond(prevEdit => ({
                        ...prevEdit,
                        rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery)
                    }));
                }
            }
            else {
                setMessages([new DangerMessage(result.errorMessage)]);
                setButtonsSecond(prevEdit => ({
                    ...prevEdit,
                    rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery)
                }));
            }

        }
    }
    const [buttons, setButtons] = useState({
        leftButtons: leftButtonsExec(
            () => {
                setShowAttachDatePanel(true);
            }, () => {
                setShowAttachWarehousePanel(true);
            },
            () => {
                setShowAttachDocumentPanel(true);
            }, DateDefault(GetDate()))
    });
    const [buttonsSecond, setButtonsSecond] = useState({
        leftButtons: leftButtonsSecond(handleClickNewQuery),
        rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery)
    });
    const GetWarehouseByStatusActive = async () => {
        let result = await GetWarehouseByActive();
        if (result.isSuccessful) {
            setWarehouseList(result.data);
        }
        else {
            setMessages([DangerMessage(result.errorMessage)]);
        }
    }
    const onChangeWarehouseType = (event, data) => {

        if (data.value === "2") {
            if (warehouseList.length > 0) {
                setWarehouseList(warehouseList.map((warehouse) => ({
                    ...warehouse,
                    selected: false,
                    filter: undefined
                })))
            }
            else {
                GetWarehouseByStatusActive();
            }
            setParam(prevEdit => ({ ...prevEdit, warehouseType: data.value }));
        }
        else {
            setWarehouseList(warehouseList.map((warehouse) => ({
                ...warehouse,
                selected: false,
            })))
            setParam(prevEdit => ({ ...prevEdit, warehouseType: data.value, warehouses: undefined }));
        }
    };
    const onChangeDocumentType = (event, data) => {
        if (data.value === "2") {
            setDocumentsList(documentsList.map((iten) => ({
                ...iten,
                selected: false,
            })))
            setParam(prevEdit => ({ ...prevEdit, documentType: data.value }));
        }
        else {
            setDocumentsList([])
            setParam(prevEdit => ({ ...prevEdit, documentType: data.value, documents: undefined }));
        }
    };
    const onChangeCheckBoxWarehouse = (event, data) => {
        if (data.checked) {
            setWarehouseList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            setWarehouseList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const onChangeWarehouseTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            setWarehouseList((prev) => {
                const updated = prev.map((item) => {
                    if (item.name.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: undefined
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: true
                        };
                    }
                });
                return updated;
            });
        }
        else {
            setWarehouseList((prev) => {
                const updated = prev.map((item) => {
                    return {
                        ...item,
                        filter: undefined
                    };
                });
                return updated;
            });
        }
    };
    const addWarehouseType = () => {
        setButtonsSecond(prevEdit => ({ ...prevEdit, rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery, daysDocument) }));
        setShowAttachWarehousePanel(false);
        setButtons({
            leftButtons: leftButtonsExec(() => {
                setShowAttachDatePanel(true);
            }, () => {
                setShowAttachWarehousePanel(true);
            },
                () => {
                    setShowAttachDocumentPanel(true);
                }, DateDefault(param),
                warehouseList.filter(x => x.selected).map(x => x.name),
                documentsList)
        });
    };
    const onChangeCheckBoxDocument = (event, data) => {
        if (data.checked) {
            setDocumentsList((prev) => (
                [...prev, event.target.name]
            ));
        }
        else {
            setDocumentsList(list => list.filter(item => item !== event.target.name));
        }
    };
    const addDocumentsType = () => {
        setButtonsSecond(prevEdit => ({ ...prevEdit, rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery, daysDocument) }));
        setShowAttachDocumentPanel(false);
        setButtons({
            leftButtons: leftButtonsExec(() => {
                setShowAttachDatePanel(true);
            }, () => {
                setShowAttachWarehousePanel(true);
            },
                () => {
                    setShowAttachDocumentPanel(true);
                }, DateDefault(param),
                warehouseList.filter(x => x.selected).map(x => x.name),
                documentsList)
        });
    };
    useEffect(() => {
        setButtonsSecond(prevEdit => ({
            ...prevEdit,
            rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery, daysDocument)
        }));
        setButtons({
            leftButtons: leftButtonsExec(() => {
                setShowAttachDatePanel(true);
            }, () => {
                setShowAttachWarehousePanel(true);
            },
                () => {
                    setShowAttachDocumentPanel(true);
                }, DateDefault(param),
                param.warehouseType === "1" ? undefined : warehouseList.filter(x => x.selected).map(x => x.name),
                param.documentType === "1" ? undefined : documentsList)
        });
        setPolicylist(undefined);
    }, [param])
    useEffect(() => {
        if (policylist && policylist.length > 0) {
            setButtonsSecond(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsExecSecond(handleClickExcel, handleClickDownload, handleClickNewQuery)
            }));
        }
    }, [policylist])
    const renderPolicyRow = (item, index) => {
        const cellLayoutClass = `justify-content-center ${item.hasAccountingPolicyNumber ? 'color-info-fill' : 'color-danger-fill'}`;
        const icon = MasterPolicyIcons.find(x => x.description === item?.documentTypeDescription)?.icon;
        const tableRow = (
            <TableRow key={index} className={`row-${item.id} ${item.found !== undefined && foundValue === item.found ? 'item-found' : ''}`}>
                <TableCell>
                    <TableCellLayout className={cellLayoutClass}>
                        {icon}
                    </TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-center">{item.warehouseKey}</TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-center">{item.rfc}</TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-center">{item.date}</TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-end">{item.amount}</TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-end">{item.ivaAmount}</TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-end">{item.iepsAmount}</TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout className="justify-content-end">{item.netAmount}</TableCellLayout>
                </TableCell>
            </TableRow>
        );

        if (item.hasAccountingPolicyNumber) {
            return tableRow;
        } else {
            return (
                <Tooltip withArrow showDelay={1} content={item.errorMessage}>
                    {tableRow}
                </Tooltip>
            );
        }
    };
    useEffect(() => {
        if (totalPaginate.length > 0 && foundValue && policylist?.length > 0) {
            setPolicylist(prevEdit => prevEdit.map(item => {
                const matchingItem = totalPaginate.find(x => x.id === item.id);
                return {
                    ...item,
                    found: matchingItem.found
                };
            }));
        }
    }, [totalPaginate])
    useEffect(() => {
        if (foundValue > 0 && policylist && policylist.length > 0) {
            let foundId = totalPaginate.find(x => x.found === foundValue)
            if (foundId) {
                const targetRow = tableRef.current.querySelector(`.row-${foundId.id}`);
                if (targetRow) {
                    targetRow.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [foundValue, policylist]);
    const GetDaysDocumentByRolId = async () => {
        let result = await GetDaysDocumentByRolIdAsync();
        if (result.data === -1) {
            setMessages([new DangerMessage("No tienes permisos para generar el reporte")]);
        }
        setDaysDocument(result.data);
    }
    useEffect(() => {
        GetDaysDocumentByRolId();
    }, [])
    useEffect(() => {
        if (daysDocument) {
            setButtonsSecond({
                leftButtons: leftButtonsSecond(handleClickNewQuery),
                rightButtons: rightButtonsSecond(handleClickTrigger, handleClickNewQuery, daysDocument)
            })
        }
    }, [daysDocument]);
    return (
        <>
            
            <BarButtons rightButtons={buttonsSecond.rightButtons}
                leftButtons={buttonsSecond.leftButtons} className="background-color-light-hover border-gray"
                leftButtonsClassName="col-lg-4 col-md-4" rightButtonsClassName="col-lg-8 col-md-8" />
            <BarButtons rightButtons={buttons.leftButtons} className="background-color-light-hover barButtons" />
            <BarMessageList messages={messages} />
            <div className='container-fluid container-scroll'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 '>
                        {policylist &&
                            <>
                                <div className="container-table-paginate">
                                    <Table size="small" className="table table-hover table-updater" ref={tableRef}>
                                    <TableHeader className="header-fixed">
                                        <TableRow>
                                            {columnsHeader.map((column) => (
                                                <TableHeaderCell key={column.columnKey}
                                                    style={{ width: column.width }}
                                                    className={'detail-table-header-title ' + column?.classAdd}>
                                                    {column.label}
                                                </TableHeaderCell>
                                            ))}
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            policylist.map(renderPolicyRow)
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            <PaginateTable totalPaginate={totalPaginate}
                                setTotalPaginate={setTotalPaginate}
                                setItemsList={setPolicylist} totalPaginateLength={totalPaginate.length}
                                foundValue={foundValue}
                                setFoundValue={setFoundValue} />
                        </>
                        }
                    </div>
                </div>
            </div>

            <OverlayDrawer position="end" open={showAttachWarehousePanel} modalType="alert"
                style={{ width: '896px' }}>
                <DrawerHeader>
                    <DrawerHeaderTitle>
                        Almacenes
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className='row'>
                        <div className='col-md-12 mt-4'>
                            <OmniRadioGroup name="warehouseType"
                                title="Almacenes"
                                orientation="horizontal"
                                items={itemsRadio}
                                default={param.warehouseType} inactivefieldEdited onChange={onChangeWarehouseType} />
                        </div>
                    </div>
                    {param.warehouseType === "2" &&
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <OmniTextBox focus title="Búsqueda de almacenes" maxLength={64} onChange={onChangeWarehouseTypeText} inactivefieldEdited />
                                <div className='row overflow-panel'>
                                    <div className='col-md-12'>
                                        {
                                            warehouseList.map((item) => (
                                                item.filter === undefined &&
                                                <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxWarehouse} id={item.id} key={item.id} name={item.name} propertyChecked={item.selected} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <OmniLabel text='Almacenes seleccionados' />
                                <div className='row overflow-panel'>
                                    <div className='col-md-12'>
                                        {
                                            warehouseList.map((item) => (
                                                item.selected === true &&
                                                <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxWarehouse} id={item.id} key={item.id} propertyChecked={true} />

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </DrawerBody>
                <DrawerFooter >
                    <div className="drawer-footer-container">
                        <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                            icon={<Image src={returnWhite} alt="return" />}
                            iconPosition="after"
                            shape="square" size="small"
                            onClick={() => {
                                setShowAttachWarehousePanel(false);
                            }}
                        >Cerrar</CompoundButton>

                        <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                            icon={<Image src={apply} alt="apply" />}
                            iconPosition="after"
                            shape="square" size="small"
                            onClick={addWarehouseType}
                        >Aplicar</CompoundButton>
                    </div>
                </DrawerFooter>
            </OverlayDrawer>
            <OverlayDrawer position="end" open={showAttachDocumentPanel} modalType="alert"
                style={{ width: '448px' }}>
                <DrawerHeader>
                    <DrawerHeaderTitle>
                        Documentos
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className='row'>
                        <div className='col-md-12 mt-4'>
                            <OmniRadioGroup name="documentType"
                                title="Documentos"
                                orientation="horizontal"
                                items={itemsRadio}
                                default={param.documentType} inactivefieldEdited onChange={onChangeDocumentType} />
                        </div>
                    </div>
                    {param.documentType === "2" &&
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <div className='row overflow-panel'>
                                    <div className='col-md-12'>
                                        <OmniCheckBox title={<div className="padding-checkbox"><Bill className="margin-checkbox-img" /> Facturas</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Facturas" propertyChecked={documentsList.some(x => x === "Facturas")} />
                                        <OmniCheckBox title={<div className="padding-checkbox"><Payment className="margin-checkbox-img" /> Complementos de pago</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Abonos Clientes" propertyChecked={documentsList.some(x => x === "Abonos Clientes")} />
                                        <OmniCheckBox title={<div className="padding-checkbox"><CustomerCreditNotes className="margin-checkbox-img" /> Notas de crédito de clientes</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Notas de crédito de clientes" propertyChecked={documentsList.some(x => x === "Notas de crédito de clientes")} />
                                        <OmniCheckBox title={<div className="padding-checkbox"> <Shopping className="margin-checkbox-img" /> Compras</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Compras" propertyChecked={documentsList.some(x => x === "Compras")} />
                                        <OmniCheckBox title={<div className="padding-checkbox"><PaymentsSuppliers className="margin-checkbox-img" /> Pagos a proveedores</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Abonos Proveedores" propertyChecked={documentsList.some(x => x === "Abonos Proveedores")} />
                                        <OmniCheckBox title={<div className="padding-checkbox"><SupplierCreditNotes className="margin-checkbox-img" /> Notas de crédito de proveedores</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Notas de crédito de provedores" propertyChecked={documentsList.some(x => x === "Notas de crédito de provedores")} />
                                        <OmniCheckBox title={<div className="padding-checkbox"> <SalesCost className="margin-checkbox-img" /> Costo de venta</div>} inactivefieldEdited onChange={onChangeCheckBoxDocument} name="Póliza costo de venta" propertyChecked={documentsList.some(x => x === "Póliza costo de venta")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </DrawerBody>
                <DrawerFooter >
                    <div className="drawer-footer-container">
                        <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                            icon={<Image src={returnWhite} alt="return" />}
                            iconPosition="after"
                            shape="square" size="small"
                            onClick={() => {
                                setShowAttachDocumentPanel(false);
                            }}
                        >Cerrar</CompoundButton>

                        <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                            icon={<Image src={apply} alt="apply" />}
                            iconPosition="after"
                            shape="square" size="small"
                            onClick={addDocumentsType}
                        >Aplicar</CompoundButton>
                    </div>
                </DrawerFooter>
            </OverlayDrawer>
            <OverlayDrawerDate showAttachDatePanel={showAttachDatePanel}
                setShowAttachDatePanel={setShowAttachDatePanel}
                setParam={setParam}
                param={param} daysDocument={daysDocument}/>
           
        </>
    )
}