import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { OmniFieldLabel } from '../../../Shared/Controls/FieldLabel/OmniFieldLabel';
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { useEffect, useState } from 'react';
import { rightButtons, leftButtons } from "../../../../Assets/Constants/BarButtons/Configurations/Roles/Authorizations/Edit";
import { GetAuthorizationsAllAsync, updateAuthorizationsAsync } from '../../../../Functions/Configuration/Roles/RoleStore';
import { OmniCallout } from '../../../Shared/Callout/OmniCallout';
import { useFieldChangeWatcherContext } from '../../../../Context/Controls/FieldChangeWatcherProvider';
import { RolesIconsCatalogs } from '../../../../Assets/Constants/Catalogs/Roles/RolesIconsCatalogs';
import { RolesIconsActions } from '../../../../Assets/Constants/Catalogs/Roles/RolesIconsActions';
import { ReactComponent as OvalIcon } from '../../../../Assets/Images/Icons/ChekedTree.svg';
import { ReactComponent as ArrowIcon } from '../../../../Assets/Images/Icons/Arrow.svg';
import { SearchBox } from "@fluentui/react";
import '../../../../Assets/Styles/Configuration/Roles/Role.css'
export const Authorization = (props) => {
    const [openCallout, setOpenCallout] = useState();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const [authorizationAdd, setAuthorizationAdd] = useState();
    const [fieldView, setFieldView] = useState(false);
    const fieldWatcher = useFieldChangeWatcherContext();
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
        GetAuthorizationsAll();
    }, [])

    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [authorizationAdd])

    const Save = async () => {
        const resultList = GetListIsSelected(authorizationAdd);
        let data = {
            roleId: props.role.id,
            listAutorizacions: resultList.listSave
        }
        let result = await updateAuthorizationsAsync(data);
        if (result.isSuccessful) {
            props.setRole((prevRole) => ({
                ...prevRole,
                listAuthorizations: authorizationAdd,
            }));
            props.setView((prevView) => ({
                ...prevView,
                viewDetail: true,
                viewEdit: false
            }));
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function GetListIsSelected() {
        let listSave = [];
        authorizationAdd.forEach((itemFirst) => {
            if (itemFirst.isSelected) {
                listSave.push(itemFirst);
            }
            listSave = listSave.concat(itemFirst.listAutorizacions.filter(x => x.isSelected));
            itemFirst.listAutorizacions.forEach((itemSecond) => {
                listSave = listSave.concat(itemSecond.listAutorizacions.filter(x => x.isSelected));
            })
        })
        return { listSave };
    }
    async function GetAuthorizationsAll() {
        let result = await GetAuthorizationsAllAsync();
        if (result.isSuccessful) {
            result.data.map((item) => {
                let firstlevel = props?.role?.listAuthorizations.find(x => x.id === item.id && (x?.isSelected === true || x?.isNotAssigned === true))
                if (firstlevel) {
                    item.isSelected = firstlevel.isSelected;
                    item.isNotAssigned = firstlevel.isNotAssigned;
                    firstlevel.listAutorizacions.map((itemFirstLevel) => {
                        let selected = item.listAutorizacions.find(x => x.id === itemFirstLevel.id && (itemFirstLevel.isSelected === true || itemFirstLevel?.isNotAssigned === true))
                        if (selected) {
                            selected.isSelected = itemFirstLevel.isSelected;
                            selected.isNotAssigned = itemFirstLevel.isNotAssigned;
                            itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                let selectedSecond = selected.listAutorizacions.find(x => x.id === itemSecondLevel.id && itemSecondLevel.isSelected === true)
                                if (selectedSecond) {
                                    selectedSecond.isSelected = true;
                                }
                            });
                        }
                    });

                }

            });
            setAuthorizationAdd(result.data)
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnChekedhancheHandler = (id) => {
        const updatedAuthorizationAdd = authorizationAdd.map((item) => {
            if (item.id === id) {

                return {
                    ...item,
                    isNotAssigned: false,
                    isSelected: !item.isSelected,
                    listAutorizacions: item.listAutorizacions.map((itemFirstLevel) => {
                        if (!item.isSelected) {
                            return itemFirstLevel;
                        } else {
                            return {
                                ...itemFirstLevel,
                                isNotAssigned: false,
                                isSelected: false,
                                listAutorizacions: itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                    if (!item.isSelected) {
                                        return itemSecondLevel;
                                    } else {
                                        return {
                                            ...itemSecondLevel,
                                            isSelected: false,

                                        };
                                    }
                                }),
                            };
                        }
                    }),
                };
            }
            return item;
        });
        setFieldView(true);
        setAuthorizationAdd(updatedAuthorizationAdd);

    };
    const OnChekedhancheHandlerFirstLevel = (id, idLevel) => {
        const updatedAuthorizationAdd = authorizationAdd.map((item) => {
            if (item.id === idLevel) {
                const updatedListAuthorizations = item.listAutorizacions.map((itemFirstLevel) => {
                    if (itemFirstLevel.id === id) {
                        return {
                            ...itemFirstLevel,
                            isNotAssigned:false,
                            isSelected: !itemFirstLevel.isSelected,
                            listAutorizacions: itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                if (!itemFirstLevel.isSelected) {
                                    return itemSecondLevel;
                                } else {
                                    return { ...itemSecondLevel, isSelected: false };
                                }
                            }),
                        };
                    } else {
                        return itemFirstLevel;
                    }
                });
                const allChildrenSelected = updatedListAuthorizations.some((child) => child.isSelected);
                return {
                    ...item,
                    isSelected: allChildrenSelected ? true : item.isSelected,
                    listAutorizacions: updatedListAuthorizations,
                };
            } else {
                return item;
            }
        });
        setFieldView(true);
        setAuthorizationAdd(updatedAuthorizationAdd);
    };
    const OnChekedhancheHandlerSecondLevel = (id, idLevel, idFirstLevel) => {
        const updatedAuthorizationAdd = authorizationAdd.map((item) => {
            if (item.id === idLevel) {
                const updatedListAuthorizations = item.listAutorizacions.map((itemFirstLevel) => {
                    if (itemFirstLevel.id === idFirstLevel) {
                        const updatedListAuthorizationsSecond = itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                            if (itemSecondLevel.id === id) {
                                return {
                                    ...itemSecondLevel,
                                    isSelected: !itemSecondLevel.isSelected
                                };
                            } else {
                                return itemSecondLevel;
                            }
                        });
                        const allChildrenSelectedFirst = updatedListAuthorizationsSecond.some((child) => child.isSelected);
                        return {
                            ...itemFirstLevel,
                            isNotAssigned: false,
                            isSelected: allChildrenSelectedFirst ? true : itemFirstLevel.isSelected,
                            listAutorizacions: updatedListAuthorizationsSecond,
                        };
                    } else {
                        return itemFirstLevel;
                    }
                });
                const allChildrenSelected = updatedListAuthorizations.some((child) => child.isSelected);
                return {
                    ...item,
                    isNotAssigned: false,
                    isSelected: allChildrenSelected ? true : item.isSelected,
                    listAutorizacions: updatedListAuthorizations,
                };
            } else {
                return item;
            }
        });
        setFieldView(true);
        setAuthorizationAdd(updatedAuthorizationAdd);
    };
    function keyDowCaptureHandler(event) {
        if (event.key.toString().toLowerCase() === "enter") {
            const value = event.target.value.toLowerCase().trim();
            if (value) {
                const updatedAuthorizationAdd = authorizationAdd.map((item) => {
                    if (item.description.toLowerCase().includes(value)) {
                        const updatedListAuthorizations = item.listAutorizacions.map((itemFirstLevel) => {
                            if (itemFirstLevel.description.toLowerCase().includes(value)) {
                                const updatedListAuthorizationsSecond = itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                    if (itemSecondLevel.description.toLowerCase().includes(value)) {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: false
                                        };
                                    } else {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: true
                                        };
                                    }
                                });
                                return {
                                    ...itemFirstLevel,
                                    isFilter: false,
                                    listAutorizacions: updatedListAuthorizationsSecond,
                                };
                            } else {
                                const updatedListAuthorizationsSecond = itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                    if (itemSecondLevel.description.toLowerCase().includes(value)) {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: false
                                        };
                                    } else {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: true
                                        };
                                    }
                                });
                                const allChildrenSelectedFirst = updatedListAuthorizationsSecond.some((child) => child.isFilter === false);
                                return {
                                    ...itemFirstLevel,
                                    isFilter: !allChildrenSelectedFirst,
                                    listAutorizacions: updatedListAuthorizationsSecond,
                                };
                            }
                        });
                        return {
                            ...item,
                            isFilter: false,
                            listAutorizacions: updatedListAuthorizations,
                        };
                    } else {
                        const updatedListAuthorizations = item.listAutorizacions.map((itemFirstLevel) => {
                            if (itemFirstLevel.description.toLowerCase().includes(value)) {
                                const updatedListAuthorizationsSecond = itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                    if (itemSecondLevel.description.toLowerCase().includes(value)) {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: false
                                        };
                                    } else {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: true
                                        };
                                    }
                                });
                                return {
                                    ...itemFirstLevel,
                                    isFilter: false,
                                    listAutorizacions: updatedListAuthorizationsSecond,
                                };
                            } else {
                                const updatedListAuthorizationsSecond = itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                                    if (itemSecondLevel.description.toLowerCase().includes(value)) {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: false
                                        };
                                    } else {
                                        return {
                                            ...itemSecondLevel,
                                            isFilter: true
                                        };
                                    }
                                });
                                const allChildrenSelectedFirst = updatedListAuthorizationsSecond.some((child) => child.isFilter === false);
                                return {
                                    ...itemFirstLevel,
                                    isFilter: !allChildrenSelectedFirst,
                                    listAutorizacions: updatedListAuthorizationsSecond,
                                };
                            }
                        });
                        const allChildrenSelected = updatedListAuthorizations.some((child) => child.isFilter === false);
                        return {
                            ...item,
                            isFilter: !allChildrenSelected,
                            listAutorizacions: updatedListAuthorizations,
                        };
                    }
                });
                setAuthorizationAdd(updatedAuthorizationAdd);
            }
            else {
                const updatedAuthorizationAddAll = authorizationAdd.map((item) => {
                    const updatedListAuthorizations = item.listAutorizacions.map((itemFirstLevel) => {
                        const updatedListAuthorizationsSecond = itemFirstLevel.listAutorizacions.map((itemSecondLevel) => {
                            return {
                                ...itemSecondLevel,
                                isFilter: false
                            };
                        });
                        return {
                            ...itemFirstLevel,
                            isFilter: false,
                            listAutorizacions: updatedListAuthorizationsSecond
                        };
                    });
                    return {
                        ...item,
                        isFilter: false,
                        listAutorizacions: updatedListAuthorizations
                    };
                });
                setAuthorizationAdd(updatedAuthorizationAddAll);
            }
        }
    }
    function Return() {
        if (fieldView === true) {
            setOpenCallout(true);
        }
        else {
            ReturnOk();
        }

    }
    const ReturnConfirm = () => {
        ReturnOk();
    }
    const ReturnOk = () => {
        props.setView((prevView) => ({
            ...prevView,
            viewDetail: true,
            viewEdit: false
        }));
        setOpenCallout(false);
    }
    const Group = (event,id) => {
        event.stopPropagation();
        const updatedAuthorizationAdd = authorizationAdd.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isGroup: !item.isGroup,
                };
            }
            return item;
        });
        setAuthorizationAdd(updatedAuthorizationAdd);
    }
    const GroupFirstLevel = (event, id, idLevel) => {
        event.stopPropagation();
        const updatedAuthorizationAdd = authorizationAdd.map((item) => {
            if (item.id === idLevel) {
                const updatedListAuthorizations = item.listAutorizacions.map((itemFirstLevel) => {
                    if (itemFirstLevel.id === id) {
                        return {
                            ...itemFirstLevel,
                            isGroup: !itemFirstLevel.isGroup,
                        };
                    } else {
                        return itemFirstLevel;
                    }
                });
                return {
                    ...item,
                    listAutorizacions: updatedListAuthorizations,
                };
            } else {
                return item;
            }
        });
        setAuthorizationAdd(updatedAuthorizationAdd);
    }
    return (
        <>
       
            <div className='col-6'>
                <OmniCard title='Listado de permisos' classCard='card-omni-without-width'>
                    <div className='row pt-3'>
                        <div className='col-lg-1 col-md-12'>
                        </div>
                        <div className='col-lg-10 col-md-12'>
                            <SearchBox className="itemsselector-search-input" placeholder="Buscar"
                                onKeyDownCapture={keyDowCaptureHandler} />
                        </div>
                        <div className='col-lg-1 col-md-12'>
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-lg-12 col-md-12'>

                            {authorizationAdd &&
                                <div className='row card-items-container card-items-container-authorizations'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div role="tree" aria-label="Item Controlled" >
                                            {authorizationAdd.map((item) => (
                                                <>
                                                    {item?.isFilter === false &&
                                                        <div tabindex="-1"
                                                            aria-level="1" aria-expanded="true">
                                                            <div className={`row catalog catalog-border catalog-font ${item.isSelected ? 'selected' : ''}`} onClick={() => OnChekedhancheHandler(item.id)}>
                                                                <div className='col-1'>
                                                                    {item.isSelected === true &&
                                                                        <OvalIcon />
                                                                    }
                                                                </div>
                                                                <div className='col-1 group-icon' onClick={(event) => Group(event, item.id) }>
                                                                    {true &&
                                                                        <ArrowIcon className={`${item?.isGroup === false ? '' : 'rotatesvg'}`}/>
                                                                    }
                                                                </div>
                                                                <div className='col-10'>
                                                                    {item.description}
                                                                </div>
                                                                
                                                            </div>
                                                            {item?.isGroup === false && item.listAutorizacions?.map((itemFirstLevel) => (
                                                                <>
                                                                    {itemFirstLevel?.isFilter === false &&
                                                                        <div role="group" >
                                                                            <div tabindex="0" aria-level="2" aria-expanded="true">
                                                                                <div className={`row catalog catalog-border catalog-font ${itemFirstLevel.isSelected ? 'selected' : ''}`} onClick={() => OnChekedhancheHandlerFirstLevel(itemFirstLevel.id, item.id)}>
                                                                                    <div className='col-1'>
                                                                                        {itemFirstLevel.isSelected === true &&
                                                                                            <OvalIcon />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='col-1 group-icon' onClick={(event) => GroupFirstLevel(event, itemFirstLevel.id, item.id)}>
                                                                                        {true &&
                                                                                            <ArrowIcon className={`${itemFirstLevel?.isGroup === false ? '' : 'rotatesvg'}`} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='col-2 text-end'>
                                                                                        {RolesIconsCatalogs.find(x => x.id === itemFirstLevel.id)?.icon}
                                                                                    </div>
                                                                                    <div className='col-8'>
                                                                                        {itemFirstLevel.description}
                                                                                    </div>
                                                                                   
                                                                                </div>
                                                                                <div role="group" >
                                                                                    {itemFirstLevel?.isGroup === false && itemFirstLevel.listAutorizacions?.map((itemSecondLevel) => (
                                                                                        <>
                                                                                            {itemSecondLevel?.isFilter === false &&
                                                                                                <div className={`row catalog catalog-border  ${itemSecondLevel.isSelected ? 'selected' : ''}`} onClick={() => OnChekedhancheHandlerSecondLevel(itemSecondLevel.id, item.id, itemFirstLevel.id)}>
                                                                                                    <div className='col-1'>
                                                                                                        {itemSecondLevel.isSelected === true &&
                                                                                                            <OvalIcon />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='col-3 text-end'>
                                                                                                        {RolesIconsActions.find(x => x.description == itemSecondLevel?.description)?.icon}
                                                                                                    </div>
                                                                                                    <div className='col-8'>
                                                                                                        {itemSecondLevel.description}
                                                                                                    </div>
                                                                                                   
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </OmniCard>
            </div>
            <div className='col-6'>
                <OmniCard title='Permisos asignados' classCard='card-omni-without-width'>
                    {authorizationAdd &&
                        <div className='row card-items-container card-items-container-authorizations-detail'>
                            <div className='col-lg-12 col-md-12'>
                                <div role="tree" aria-label="Item Controlled" >
                                    {authorizationAdd.map((item) => (
                                        <>
                                            {(item?.isSelected === true || item?.isNotAssigned === true) &&
                                                <div>
                                                    <div className="row catalog catalog-border catalog-font">
                                                        <div className={`col-12 ${item?.isNotAssigned === true ? 'color-danger' : ''}`} >
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                    {item.listAutorizacions?.map((itemFirstLevel) => (
                                                        <>
                                                            {(itemFirstLevel?.isSelected === true || itemFirstLevel?.isNotAssigned === true) &&
                                                                <div role="group">
                                                                    <div className="row catalog catalog-border catalog-font">
                                                                        <div className='col-2 text-center'>
                                                                            {RolesIconsCatalogs.find(x => x.id === itemFirstLevel.id)?.icon}
                                                                        </div>
                                                                        <div className={`col-10 ${itemFirstLevel?.isNotAssigned === true ? 'color-danger' : ''}`}>
                                                                            {itemFirstLevel.description}
                                                                        </div>
                                                                    </div>
                                                                    <div role="group">
                                                                        {itemFirstLevel.listAutorizacions?.map((itemSecondLevel) => (
                                                                            <>
                                                                                {itemSecondLevel?.isSelected === true &&
                                                                                    <div className="row catalog catalog-border">
                                                                                        <div className='col-3 text-end'>
                                                                                            {RolesIconsActions.find(x => x.description == itemSecondLevel?.description)?.icon}
                                                                                        </div>
                                                                                        <div className='col-9'>
                                                                                            {itemSecondLevel.description}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </OmniCard>
            </div>
                    {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={ReturnConfirm}
            orientation={5}
            title='Confirmación'
            content={"¿Desea salir sin guardar los cambios?"}
            leftButton={'Si'}
            rightButton={'No'}
            backgroundColorLeft={'var(--light-hover)'}
            colorLeft={'var(--danger)'}
            backgroundColorRight={'var(--light-hover)'}
            colorRight={'var(--dark)'}
            borderLeft={'none'}
            borderRight={'none'}
            buttonId={"BtnRegresar"}
            barGroupKey={'rightCommandBar'}
        />

    }
        </>
    )
}