import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Warehouse/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Configuration/Warehouse/WarehouseStore'
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import Detail from './Detail';
import { Weekdays } from '../../../Assets/Constants/Enums/Weekdays';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const { useAccounting } = useAccountingConfigurationContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [listBranch, setListBranch] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [showErrors, setShowErrors] = useState({
        clave: undefined,
        name: undefined,
    });
    const [warehouseInformation, setWarehouseInformation] = useState(
        {
            listDaysReceiveWarehouseResult: [false, false, false, false, false, false, false],
            dailyReceptions: 0,
            maximumTons: 0,
            maximumVolume: 0,
            maximumVolume: 0,
            maximumBoxes: 0,
            inventoryDays: 0,
            cuvikBranchId: 0,
        });
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };

    const AddConfirm = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {

            var data = warehouseInformation;
            let errorMessages = [];
            if (data) {
                data.maximumBoxes = data.maximumBoxes && data.maximumBoxes !== ''
                    ? data.maximumBoxes.toString().replace(",", "")
                    : data.maximumBoxes;

                if (!data.warehouseKey) {
                    errorMessages.push("El campo clave es obligatorio");
                }
                if (!data.name) {
                    errorMessages.push("El campo nombre es obligatorio");
                }
                if (!data.cuvikBranchId) {
                    errorMessages.push("El campo sucursal es obligatorio");
                }
                else if (data.cuvikBranchId) {
                    var branch = listBranch.find((x) => x.key == data.cuvikBranchId);
                    data.cuvikBranchKey = branch.brachKey;
                    data.useWarehouse = branch.useWarehouse;
                }
            }
            if (useAccounting) {
                if (!data.accountingAccount) {
                    errorMessages.push("El campo almacén es obligatorio");
                }
                if (!data.accountingAccountCostSale) {
                    errorMessages.push("El campo costo de venta es obligatorio");
                }
                if (!data.accountingAccountSale) {
                    errorMessages.push("El campo ventas es obligatorio");
                }
                if (!data.accountingAccountReturnSales) {
                    errorMessages.push("El campo devolución sobre ventas es obligatorio");
                }
                if (!data.accountingAccountPurchases) {
                    errorMessages.push("El campo compras es obligatorio");
                }
                if (!data.accountingAccountReturnPurchases) {
                    errorMessages.push("El campo devolución de compras es obligatorio");
                }
                if (data.accountingAccount !== data.accountingAccountPurchases) {
                    errorMessages.push("Los campos almacén y compras deben ser iguales");
                }
            }
            if (errorMessages.length > 0) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages.map(x => x).join('. '))]);
            }
            else {
                if (useAccounting) {
                    data.accountingAccount = data.accountingAccount.endsWith(".") ? data.accountingAccount.slice(0, -1) : data.accountingAccount;
                    data.accountingAccountCostSale = data.accountingAccountCostSale.endsWith(".") ? data.accountingAccountCostSale.slice(0, -1) : data.accountingAccountCostSale;
                    data.accountingAccountSale = data.accountingAccountSale.endsWith(".") ? data.accountingAccountSale.slice(0, -1) : data.accountingAccountSale;
                    data.accountingAccountDiscountSales = data.accountingAccountDiscountSales.endsWith(".") ? data.accountingAccountDiscountSales.slice(0, -1) : data.accountingAccountDiscountSales;
                    data.accountingAccountReturnSales = data.accountingAccountReturnSales.endsWith(".") ? data.accountingAccountReturnSales.slice(0, -1) : data.accountingAccountReturnSales;
                    data.accountingAccountPurchases = data.accountingAccountPurchases.endsWith(".") ? data.accountingAccountPurchases.slice(0, -1) : data.accountingAccountPurchases;
                    data.accountingAccountReturnPurchases = data.accountingAccountReturnPurchases.endsWith(".") ? data.accountingAccountReturnPurchases.slice(0, -1) : data.accountingAccountReturnPurchases;
                }
                let result = await AddAsync(data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, result.data);
                    data.id = result.data;
                    addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    };
    useEffect(() => {
        const GetBranchs = async () => {
            let result = await GetBranchsAsync();
            if (result.isSuccessful) {
                setListBranch(result.data);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
        GetBranchs();
        if (useAccounting)
        {
            fieldWatcher.AddRequiredControls('Almacén');
            fieldWatcher.AddRequiredControls('Costo de venta');
            fieldWatcher.AddRequiredControls('Ventas');
            fieldWatcher.AddRequiredControls('Devolución sobre ventas');
            fieldWatcher.AddRequiredControls('Compras');
            fieldWatcher.AddRequiredControls('Devolución de compras');
        }
    }, [])

    const onBlurWarehouseKey = (event) => {
        ValidText(event, "warehouseKey", "clave");
    };
    const onBlurName = (event) => {
        ValidText(event, "name", "nombre");
    };
    const onBlurAccountingAccount = (event) => {
        ValidText(event, "accountingAccount", "almacén");
    }
    const onBlurAccountingAccountCostSale = (event) => {
        ValidText(event, "accountingAccountCostSale", "costo de venta");
    }
    const onBlurAccountingAccountSale = (event) => {
        ValidText(event, "accountingAccountSale", "ventas");
    }
    const onBlurAccountingAccountReturnSales = (event) => {
        ValidText(event, "accountingAccountReturnSales", "devolución sobre ventas");
    }
    const onBlurAccountingAccountPurchases = (event) => {
        ValidText(event, "accountingAccountPurchases", "compras");
    }
    const onBlurAccountingAccountReturnPurchases = (event) => {
        ValidText(event, "accountingAccountReturnPurchases", "devolución de compras");
    }
    function ValidText(event, property, fild) {
        let text = event.target.value.trim();
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    requiredOmni
                                                    focus
                                                    width="85%"
                                                    title='Clave'
                                                    maxLength={3}
                                                    property="warehouseKey"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.warehouseKey}
                                                    blur={onBlurWarehouseKey}
                                                    validationMessage={showErrors.warehouseKey}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    requiredOmni
                                                    width="85%"
                                                    title='Nombre'
                                                    maxLength={64}
                                                    property="name"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.name}
                                                    blur={onBlurName}
                                                    validationMessage={showErrors.name}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCheckBox
                                                    title='Este almacén es bodega'
                                                    propertyId="isWarehouse"
                                                    edit={setWarehouseInformation}
                                                    propertyChecked={warehouseInformation?.isWarehouse}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                {
                                                    listBranch ?
                                                        <OmniDropdown
                                                            requiredOmni
                                                            title='Sucursal'
                                                            items={listBranch}
                                                            property="cuvikBranchId" name="cuvikBranch"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.cuvikBranch}
                                                            selected={warehouseInformation?.cuvikBranchId}
                                                            validationMessage={showErrors.cuvikBranchId}
                                                        />
                                                        :
                                                        <OmniDropdown
                                                            requiredOmni
                                                            disabled
                                                            title='Sucursal'
                                                            items={null}
                                                            validationMessage={showErrors.cuvikBranchId} />
                                                }
                                            </div>
                                        </div>
                                    </OmniCard>

                                    {/*<OmniCard title='Resurtir almacén'>*/}
                                    {/*    <div className='col-lg-12 col-md-12'>*/}
                                    {/*        <OmniCheckBox title='Se resurten todos los artículos de este almacén'*/}
                                    {/*            register={{ ...register('restockWarehouse') }}*/}
                                    {/*            infoText="Se resurten todos los artículos de este almacén" />*/}
                                    {/*    </div>*/}
                                    {/*</OmniCard>*/}

                                </div>

                                <div className='col-md-auto'>

                                    <OmniCard title='Capacidad de recibo'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    inputType="number"
                                                    length="4"
                                                    width="85%"
                                                    title='Recepciones por día'
                                                    property="dailyReceptions"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.dailyReceptions}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    inputType="number"
                                                    length="4"
                                                    width="85%"
                                                    title='Toneladas por día'
                                                    property="maximumTons"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.maximumTons}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    inputType="number"
                                                    length="4"
                                                    width="85%"
                                                    title='Volúmen por día'
                                                    property="maximumVolume"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.maximumVolume}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    inputType="number"
                                                    length="8"
                                                    width="85%"
                                                    title='Cajas por día'
                                                    property="maximumBoxes"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.maximumBoxes}
                                                />
                                            </div>
                                        </div>
                                    </OmniCard>
                                    <OmniCard title='Resurtido'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox
                                                    inputType="number"
                                                    length="4"
                                                    width="85%"
                                                    title='Días de inventario'
                                                    property="inventoryDays"
                                                    edit={setWarehouseInformation}
                                                    value={warehouseInformation?.inventoryDays}
                                                />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            {
                                                Weekdays.map((day) => (
                                                    <div className='col-lg-4 col-md-12'>
                                                        <OmniSwitch
                                                            label={day.name}
                                                            propertyId="listDaysReceiveWarehouseResult"
                                                            edit={setWarehouseInformation}
                                                            itemList={day.number}
                                                            checked={warehouseInformation.listDaysReceiveWarehouseResult[day.number]}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </OmniCard>

                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    {
                        useAccounting &&
                        <PivotItem headerText='Contabilidad'>
                                <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Cuentas contables'>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className='row'>
                                                    <OmniTextBox
                                                        focus
                                                        requiredOmni
                                                        title='Almacén'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccount"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccount}
                                                        blur={onBlurAccountingAccount}
                                                        validationMessage={showErrors.accountingAccount}
                                                    />
                                                </div>
                                                <div className='row'>
                                                        <OmniTextBox
                                                        requiredOmni
                                                        title='Costo de venta'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccountCostSale"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccountCostSale}
                                                        blur={onBlurAccountingAccountCostSale}
                                                        validationMessage={showErrors.accountingAccountCostSale}
                                                    />
                                                </div>
                                                <div className='row'>
                                                        <OmniTextBox
                                                        requiredOmni
                                                        title='Ventas'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccountSale"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccountSale}
                                                        blur={onBlurAccountingAccountSale}
                                                        validationMessage={showErrors.accountingAccountSale}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox
                                                        title='Descuentos sobre ventas'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccountDiscountSales"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccountDiscountSales}
                                                        validationMessage={showErrors.accountingAccountDiscountSales}
                                                    />
                                                </div>
                                                <div className='row'>
                                                        <OmniTextBox
                                                        requiredOmni
                                                        title='Devolución sobre ventas'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccountReturnSales"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccountReturnSales}
                                                        blur={onBlurAccountingAccountReturnSales}
                                                        validationMessage={showErrors.accountingAccountReturnSales}
                                                    />
                                                </div>
                                                <div className='row'>
                                                        <OmniTextBox 
                                                        requiredOmni
                                                        title='Compras'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccountPurchases"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccountPurchases}
                                                        blur={onBlurAccountingAccountPurchases}
                                                        validationMessage={showErrors.accountingAccountPurchases}
                                                    />
                                                </div>
                                                <div className='row'>
                                                        <OmniTextBox
                                                        requiredOmni
                                                        title='Devolución de compras'
                                                        maxLength={16}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                        property="accountingAccountReturnPurchases"
                                                        edit={setWarehouseInformation}
                                                        value={warehouseInformation?.accountingAccountReturnPurchases}
                                                        blur={onBlurAccountingAccountReturnPurchases}
                                                        validationMessage={showErrors.accountingAccountReturnPurchases}
                                                    />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                    }
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }
        </>
    );
}