import { Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useComponentContext, useGeneralStatesContext, } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import ItemsList from "./ItemsList";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import BarMessages from "../../Shared/BarMessages/BarMessages";
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
export default function ItemsSelector(props) {
    const component = useComponentContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const axiosPrivate = useAxiosPrivate();
    const [originalItems, setOriginalItems] = useState();
    const [rendered, setRendered] = useState(false);
    const [visibleSkeleton, setVisibleSkeleton] = useState(false);
    const[actualPage,setActualPage]=useState(1);
    const[totalInPage, setTotalInPage]=useState(50)
    const[pageSize, setPageSize]=useState(50);
    const 
    {
        setComponent,
        id,
        setId,
        items, 
        setItems, 
        totalItems,
        setTotalItems,
        statusFilter, 
        textFilter, 
        setGetURL,
        setSearch
    }= useGeneralStatesContext();
    const getItems = async () =>  {
            var descriptionFilter = textFilter === '*' ? "" : textFilter;
            const statusFilterParam: StatusFilterParam =
            {
                statusFilter: Number(statusFilter),
                description: descriptionFilter,
                pageNumber:actualPage,
                pageSize:pageSize,
            };
            var  response=null;
            try{
                response = await axiosPrivate.post
                (
                    props.urlGetItems
                    , statusFilterParam
                    , {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,

                    }
                );
            }
            catch(ex){}
        if (response?.data?.data?.length > 0) {
            setItems(response.data.data);
            setId(response.data.data[0].id);
            setComponent(props.detailComponent);
        }
        else {
            setItems([]);
        }
        setVisibleSkeleton(false);
        setSearch(true);
    }
    const getTotalItems = async () =>  {
        var descriptionFilter = textFilter === '*' ? "" : textFilter;
        const statusFilterParam: StatusFilterParam =
        {
            statusFilter: Number(statusFilter),
            description: descriptionFilter,
            pageNumber:actualPage,
            pageSize:pageSize,
        };
        var  response=null;
        try{
            response = await axiosPrivate.post
            (
                props.urlGetTotalItems
                , statusFilterParam
                , {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,

                }
            );
        }
        catch(ex){
            
        }
        setTotalItems(response?.data?.id);
        setVisibleSkeleton(false);
        setSearch(true);
    }
    const nextPage=()=>{
        if(actualPage*pageSize<totalItems){
            setActualPage(actualPage+1)
            setTotalInPage(pageSize*actualPage);
        }
        else{
            setTotalInPage(totalItems);
        }
        
    }
    const previousPage=()=>{
        if(actualPage>1){
            setActualPage(actualPage-1)
        }
    }

    useEffect(() => {
        if (rendered == false && props.getInLoad == true) {
            setGetURL(props.urlGetItems)
            setRendered(true);
        }
    }, [])

    useEffect(()=>{
        if (totalItems !==0 && totalItems!==undefined) {
            getItems();
        }
    },[actualPage])
    useEffect(()=>{
        if (totalItems !==0 && totalItems!==undefined) {
            getTotalItems();
            setActualPage(1);
        }
    },[textFilter, statusFilter])

    fieldWatcher.hasPendingChangesRef.current = false;

    
    return (
        <div id="stkItemSelector" className="item-selector">
            <div id="stkItemList" className="item-selector-list-content">
                <ItemsList
                    visibleSkeleton={visibleSkeleton}
                    setVisibleSkeleton={setVisibleSkeleton}
                    title={props.title}
                    image={props.image}
                    items={items}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    actualPage={actualPage}
                    totalInPage={totalInPage}
                    originalItems={originalItems}
                    setitems={props.setitems}
                    detailComponent={props.detailComponent}
                    getItems={getItems}
                    getTotalItems={getTotalItems}
                    searchActiveInRender={props.searchActiveInRender}
                    omniKey={props.omniKey}
                    omniValue={props.omniValue}
                    omniAcronym={props.omniAcronym}
                />
            </div>
            <div id="stkItemContainer"
                className="stack-itemsselector-container">
                <BarMessages />
                {

                    component == undefined
                        ? props.indexComponent === undefined ?
                            <BarButtons rightButtons={props.defaultButtonsRightBar}
                                leftButtons={props.defaultButtonsLeftBar} /> : props.indexComponent
                        : component
                }
            </div>
        </div>
    )
}

function SetFocus(id){
    var element=document.getElementById("itm"+id);
    if(element!== undefined && element!==null){
        element.focus();
    }
}
