import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Documents/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Configuration/Documents/DocumentStore';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import {SetAxiosPrivate as setAxiosPrivateDocumentType, GetToAutocomplete} from '../../../Functions/Catalogs/DocumentTypes/DocumentTypeStore';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Document.svg').default
}
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    setAxiosPrivateDocumentType(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    var initialDocument={
        branchId: "",
        documentTypeId: "",
        location: "",
        visibleInMatrix:false,
        isFiscal:false
    }
    const[document, setDocument]=useState(initialDocument); 
    const [branches, setListBranch] = useState();
    const[documentTypes, setDocumentTypes]=useState();

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
    let errorMessages = fieldWatcher.ShowRequiredControls();
    if (errorMessages.length > 0) {
        setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
    }
    else {
            var data = getValues();
            var branchId=data.branchId;
            data.cuvikBranchId=branchId.split("#")[0];
            let branch=branches.find(b=>parseInt(b.key)==parseInt(data.cuvikBranchId))
            data.cuvikBranchKey=branch!==undefined?branch.brachKey:"";
            data.visibleInMatrix=document.visibleInMatrix;
            data.isFiscal=document.isFiscal;
            let result = await AddAsync(data, useForeignKey);
            if (result.isSuccessful) {
                let documentType=documentTypes.filter((type)=>type.key.toString()==data.documentTypeId.toString());
                let documentTypeDescription=documentType[0].text;
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                addNewItem({ ...data, acronym: localStorage.getItem("userName"), description:documentTypeDescription });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    
   
    useEffect(() =>
        {
            const GetBranchs = async () => {
                let result = await GetBranchsAsync();
                if (result.isSuccessful) {
                    setListBranch(result.data);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
            GetBranchs();
            const GetDocumentTypes = async () => {
                let result = await GetToAutocomplete();
                if (result.isSuccessful) {
                    setDocumentTypes(result.data);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
            GetDocumentTypes();
        }, [])
  
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información">
                        <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>                                   
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniSelect 
                                                requiredOmni  
                                                setValue={setValue} 
                                                width='100%' 
                                                title='Tipo de documento' 
                                                register={{ ...register('documentTypeId') }} 
                                                items={documentTypes}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniTextBox title='Serie' requiredOmni  setValue={setValue} maxLength={3}  register={{ ...register('serie') }} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniSelect requiredOmni  setValue={setValue} width='100%' title='Sucursal' register={{ ...register('branchId') }} items={branches} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniSwitch
                                                label={"Documento es visible en matriz"}
                                                edit={setDocument}
                                                propertyId="visibleInMatrix"
                                                checked={document?.visibleInMatrix}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniSwitch
                                                label={"Es fiscal"}
                                                edit={setDocument}
                                                propertyId="isFiscal"
                                                checked={document?.isFiscal}
                                            />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            <OmniCallout
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={AddConfirm}
                title='Confirmación'
                content='¿Desea guardar los cambios?'
                leftButton={'Si'}
                rightButton={'Regresar'}
                backgroundColorLeft={'var(--success)'}
                colorLeft={'white'}
                backgroundColorRight={'var(--secondary)'}
                colorRight={'white'}
                buttonId={'BtnGuardar'}
                barGroupKey={'rigthCommandBar'}
            />
        </>
    )
}
