import { useEffect, useState } from "react";
import { Map } from "../../Shared/Maps/Map";
import { GetRoutesAsync, SetAxiosPrivate, GetCoordinatesByRouteDayAsync, GetCoordinatesByRouteIdAsync, GetAllCoordinatesAsync } from '../../../Functions/Maps/RoutesMaps/RouteMapStore'
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import '../../../Assets/Styles/Maps/RoutesMaps/RouteMap.css';
import { ReactComponent as ImgAllRoutes } from '../../../Assets/Images/Icons/RutaDeVenta.svg';
import { ReactComponent as ImgMap } from '../../../Assets/Images/Icons/Mapa.svg';
import { ReactComponent as ImgCalendar } from '../../../Assets/Images/Icons/Calendario.svg';
import { ReactComponent as OvalIcon } from '../../../Assets/Images/Icons/Oval.svg';
import { Weekdays } from "../../../Assets/Constants/Enums/Weekdays";

export default function RouteMap(){
    const defaultLatitude=19.03200;
    const defaultLongitude=-98.20323;
    const[routes, setRoutes]=useState([]);
    const[searched, setSearched]=useState(false);
    const[coordinates,setCordinates]=useState([{latitude:defaultLatitude, longitude:defaultLongitude}])
    const[withMarkers, setWithMarkers]=useState(false);
    const[allRoutesSelected, setAllRoutesSelected]=useState(false);
    const axiosPrivate=useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate)

    const GetRoutes=async()=>{
        let result = await GetRoutesAsync();
        if (result.isSuccessful) {
            setRoutes(result.data);
            setSearched(true);
        }
    }

    const GetCoordinatesByRouteDay=async(day)=>{
        let result = await GetCoordinatesByRouteDayAsync(day);
        if (result.isSuccessful) {
            setWithMarkers(true);
            setCordinates(result.data);
            setSearched(true);
        }
        else{
            var defaultCoordinates=[
                {
                    id:1, 
                    latitude:defaultLatitude, 
                    longitude:defaultLongitude
                }
            ]
            setWithMarkers(false);
            setCordinates(defaultCoordinates);
        }
    }

    const dayClick=(day)=>{
        GetCoordinatesByRouteDay(day);
        
        var newRoutes=[];
        routes?.map((route)=>{
            var newRoute=route;
            var newDays=[];
            route.daysRoutes?.map((dayRoute)=>{
                var newDay=dayRoute;
                if(dayRoute.id==day){
                    newDay.isSelected=true;
                }
                else{
                    newDay.isSelected=false;
                }

                newDays.push(newDay);
            })
            newRoute.isSelected=false;
            newRoute.daysRoutes=newDays;
            newRoutes.push(newRoute)
        });
        setRoutes(newRoutes);
        setAllRoutesSelected(false)
    }

    const GetCoordinatesByRouteId=async(routeId)=>{
        let result = await GetCoordinatesByRouteIdAsync(routeId);
        if (result.isSuccessful) {
            setWithMarkers(true);
            setCordinates(result.data);
            setSearched(true);
        }
        else{
            var defaultCoordinates=[
                {
                    id:1, 
                    latitude:defaultLatitude, 
                    longitude:defaultLongitude
                }
            ]
            setWithMarkers(false);
            setCordinates(defaultCoordinates);
        }
    }

    const routeClick=(routeId)=>{
        GetCoordinatesByRouteId(routeId);
        var newRoutes=[];
        routes?.map((route)=>{
            var newRoute=route;
            if(route.id==routeId){
                newRoute.isSelected=true;
            }
            else{
                newRoute.isSelected=false;
            }
            newRoute.daysRoutes?.map((dayRoute)=>{
                dayRoute.isSelected=false;
            })
            newRoutes.push(newRoute)
        });
        setRoutes(newRoutes);
        setAllRoutesSelected(false)
    }

    const GetAllRoutes=async()=>{
        let result = await GetAllCoordinatesAsync();
        if (result.isSuccessful) {
            setWithMarkers(true);
            setCordinates(result.data);
            setSearched(true);
        }
        else{
            var defaultCoordinates=[
                {
                    id:1, 
                    latitude:defaultLatitude, 
                    longitude:defaultLongitude
                }
            ]
            setWithMarkers(false);
            setCordinates(defaultCoordinates);
        }
    }

    const allRoutesClick=()=>{
        GetAllRoutes()
        var newRoutes=[];
        routes?.map((route)=>{
            var newRoute=route;
            newRoute.isSelected=false;
            newRoute.daysRoutes?.map((dayRoute)=>{
                dayRoute.isSelected=false;
            })
            newRoutes.push(newRoute)
        });
        setRoutes(newRoutes);
        setAllRoutesSelected(true)
    }

    useEffect(()=>{
        if(searched==false){
            GetRoutes();
        }
    },[]);

    return(
        <>
            <div className="row">
                <div className="col-lg-4 col-md-4 tree-section">
                    <div role="tree" aria-label="Item Controlled"  className="tree-container" >
                        <div className={`row catalog catalog-border catalog-font tree-item ${allRoutesSelected?'selected':''}`} onClick={()=>allRoutesClick()}>
                            <div className='col-lg-2 col-md-2 '>
                                <ImgAllRoutes />
                            </div>
                            <div className='col-lg-8 col-md-8'>
                                Todas las rutas
                            </div>
                            <div className='col-lg-2 col-md-2'>
                                {
                                    allRoutesSelected
                                    ?<OvalIcon />
                                    :<></>
                                }  
                            </div>
                        </div>
                        {routes.map((item) => (
                            <>
                                <div key={"r"+item.id} role="group">
                                    <div className={`row catalog catalog-border catalog-font tree-item ${item.isSelected?'selected':''}`} onClick={()=>routeClick(item.id)}>
                                        <div className='col-lg-2 col-md-2 padding-firstlevel'>
                                            <ImgMap />
                                        </div>
                                        <div className='col-lg-8 col-md-8'>
                                            {item.description}
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            {
                                                item.isSelected
                                                ?<OvalIcon />
                                                :<></>
                                            }  
                                        </div>
                                    </div>
                                    <div role="group">
                                        {
                                            item.daysRoutes?.map((day)=>(
                                                <div key={"d"+day.id} className={`row catalog catalog-border tree-item ${day.isSelected?'selected':''}`} onClick={()=>dayClick(day.id)}>
                                                    <div className='col-lg-3 col-md-3 padding-secondlevel'>
                                                        <ImgCalendar />
                                                    </div>
                                                    <div className='col-lg-7 col-md-7'>
                                                        { day.description }
                                                    </div>
                                                    <div className='col-lg-2 col-md-2'>
                                                        {
                                                            day.isSelected
                                                            ?<OvalIcon />
                                                            :<></>
                                                        }
                                                            
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
            </div>
                <div className="col-lg-8 col-md-8 map-section">
                    <Map coordinates={coordinates} withMarkers={withMarkers}/>
                </div>
            </div>
        </>
    );
}